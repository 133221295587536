/**
 * Machine details page component.
 *
 * @category pages
 * @module machine-details
 */

import React, { ReactElement, ReactNode } from "react";
import { t } from "@lingui/macro";

import { Button } from "_/components/button";
import { DateTime } from "_/components/date-time";
import { JobsListing } from "_/components/jobs-listing";
import { JsonTree } from "_/components/json-tree";
import { MachineEvents } from "_/components/machine-events";
import { MachineHeader } from "_/components/machine-header";
import { MachineTemperature } from "_/components/machine-temperature";
import { Route, Switch } from "_/components/router";

import { machineRoutes, machineUrls } from "_/routes";

import {
  Machine,
  TelemetryEvent,
  TelemetryEventKind,
  useMachineEvents,
  useMachineSnapshot,
} from "_/data/machines";
import { imageURL, thumbURL } from "_/data/images";
import { useIsAonUser } from "_/data/users";

import * as S from "./styled";

export type MachineDetailsProps = {
  machine: Machine;
};

const LastMachineImage = ({ machine }: MachineDetailsProps) => {
  const { data: events } = useMachineEvents({
    machineId: machine.id,
    from: undefined,
    to: undefined,
    limit: 1,
    kind: ["image"] as TelemetryEventKind[],
    order: "desc",
  });

  if (!events || events.length === 0) return null;

  const event = events[0] as TelemetryEvent<"image">;
  const {
    data: { imageId },
    timestamp,
  } = event;

  const src = imageURL(imageId);
  const thumb = thumbURL(imageId);

  return (
    <S.LastMachineImage>
      <S.Image src={src} thumb={thumb} />
      <div>
        <DateTime value={new Date(timestamp / 1_000)} />
      </div>
    </S.LastMachineImage>
  );
};

const MachineOverview = ({ machine }: MachineDetailsProps): ReactNode => {
  const isAonUser = useIsAonUser();

  const snapshotWidget = isAonUser ? (
    <S.Widget>
      <S.WidgetTitleContainer>
        <h2>{t`common.snapshot`}</h2>
      </S.WidgetTitleContainer>
      <MachineSnapshotJson machine={machine} />
    </S.Widget>
  ) : null;

  return (
    <S.MachineOverviewContainer>
      <S.NestedWidgetContainer>
        <S.UseRemainingWidth>
          <S.Widget>
            <S.WidgetTitleContainer>
              <h2>{t`common.current-temps`}</h2>
              <S.Link to={machineUrls.thermals(machine.id)}>
                <Button size="small">{t`common.thermal-history`}</Button>
              </S.Link>
            </S.WidgetTitleContainer>
            <S.MachineTemperatureDisplay machine={machine} view="wide" />
          </S.Widget>
        </S.UseRemainingWidth>
        <S.Widget>
          <S.WidgetTitleContainer>
            <h2>{t`common.last-image-captured`}</h2>
          </S.WidgetTitleContainer>
          <S.MachineOverviewInfo>
            <LastMachineImage machine={machine} />
          </S.MachineOverviewInfo>
        </S.Widget>
      </S.NestedWidgetContainer>
      <S.Widget>
        <S.WidgetTitleContainer>
          <h2>{t`common.jobs`}</h2>
        </S.WidgetTitleContainer>
        <JobsListing variant="machine" limit={50} machine={machine} />
      </S.Widget>
      {snapshotWidget}
    </S.MachineOverviewContainer>
  );
};

const MachineSnapshotJson = ({
  machine,
}: MachineDetailsProps): ReactElement => {
  const { data: snapshot, error } = useMachineSnapshot(machine.id);

  if (error) {
    console.error("failure retrieving machine snapshot", error);
    return (
      <div>
        <span>{t`common.error`}:</span>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </div>
    );
  }

  if (!snapshot) {
    return <></>;
  }

  const data = snapshot?.snapshot || {};

  if (!data) {
    return <div>{t`common.no-data`}</div>;
  }

  return (
    <div>
      <span>
        {t`components.machine-snapshot-json.updated-at`}:{" "}
        <DateTime
          /** Snapshot timestamp is in microseconds because it is from klipper. */
          value={snapshot?.timestamp / 1000}
          defaultValue={t`common.unknown`}
        />
      </span>
      <JsonTree data={data} hideRoot />
    </div>
  );
};

const MachineConfiguration = ({
  machine,
}: MachineDetailsProps): ReactElement => {
  return (
    <S.MachineOverviewContainer>
      <S.MachineInfo>
        <S.MachineInfoItem>
          <S.MachineInfoLabel>{t`common.serial-number`}</S.MachineInfoLabel>
          <S.MachineInfoValue>{machine.hardware.serial}</S.MachineInfoValue>
        </S.MachineInfoItem>
        <S.MachineInfoItem>
          <S.MachineInfoLabel>{t`common.software-version`}</S.MachineInfoLabel>
          <S.MachineInfoValue>
            {machine.metadata?.agentVersion || "--"}
          </S.MachineInfoValue>
        </S.MachineInfoItem>
        <S.MachineInfoItem>
          <S.MachineInfoLabel>
            {t`components.machine-header.lan-address`}
          </S.MachineInfoLabel>
          <S.MachineInfoValue>
            {(machine.lanAddress && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`http://${machine.lanAddress}`}
              >
                {machine.lanAddress}
              </a>
            )) ||
              "--"}
          </S.MachineInfoValue>
        </S.MachineInfoItem>
        <S.MachineInfoItem>
          <S.MachineInfoLabel>
            {t`components.machine-header.wlan-address`}
          </S.MachineInfoLabel>
          <S.MachineInfoValue>
            {(machine.wlanAddress && (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`http://${machine.wlanAddress}`}
              >
                {machine.wlanAddress}
              </a>
            )) ||
              "--"}
          </S.MachineInfoValue>
        </S.MachineInfoItem>
      </S.MachineInfo>
    </S.MachineOverviewContainer>
  );
};

export const MachineDetails = ({
  machine,
}: MachineDetailsProps): ReactElement => {
  return (
    <S.Wrapper>
      <MachineHeader machine={machine} />
      <Switch>
        <Route path={machineRoutes.index}>
          <MachineOverview machine={machine} />
        </Route>
        <Route path={machineRoutes.thermals}>
          <MachineTemperature machine={machine} />
        </Route>
        <Route path={machineRoutes.configuration}>
          <MachineConfiguration machine={machine} />
        </Route>
        <Route path={machineRoutes.events}>
          <MachineEvents machine={machine} />
        </Route>
      </Switch>
    </S.Wrapper>
  );
};
